/* Geral */
.testimonials-section {
  text-align: center;
  padding: 3rem 1.5rem;
  background-color: #3ace8424;
  font-family: 'Poppins', sans-serif;
  position: relative;
  margin: 0 auto;
}

.testimonials-section-secondary {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.testimonials-section h2 {
  font-size: 2.5rem; /* Tamanho padrão do título */
  color: #333;
  margin-bottom: 0.5rem;
  display: inline-block; /* Permite que o ::after seja relativo ao h2 */
  position: relative;
  animation: fadeInDown 0.8s ease-out;
}

.testimonials-section h2::after {
  content: '';
  width: 50%; /* Define a largura da linha como 50% do título */
  height: 3px;
  background-color: #00c360;
  position: absolute;
  left: 50%; /* Posiciona a linha no centro do título */
  bottom: -8px;
  transform: translateX(-50%); /* Centraliza a linha em relação ao título */
  border-radius: 2px;
}

.section-subtitle {
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 2.5rem;
  animation: fadeInUp 0.8s ease-out;
}

.testimonial-card {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.testimonial-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);
}

.testimonial-header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.testimonial-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #00c360;
  margin-right: 1rem;
}

.testimonial-info h4 {
  font-size: 1.2rem;
  justify-self: left;
  color: #333;
  margin: 0;
  font-weight: bold;
}

.location {
  font-size: 0.9rem;
  color: #777;
  margin-top: 0.2rem;
}

.rating {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}

.rating .star {
  color: #f39c12;
  font-size: 1rem;
  margin-left: 0.2rem;
}

.testimonial-text {
  font-size: 1rem;
  color: #555;
  margin-top: 1rem;
  line-height: 1.6;
  flex-grow: 1;
}

/* Personalização dos Dots */
.custom-dot-list-style {
  display: flex;
  justify-content: center;
  padding: 10px 0;
  margin-top: 20px;
}

.custom-dot-list-style .react-multi-carousel-dot button {
  background: #ccc;
  border: none;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: 0 6px;
  padding: 0;
  transition: background 0.3s;
}

.custom-dot-list-style .react-multi-carousel-dot--active button {
  background: #00c360;
}

/* Setas de Navegação */
.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(40, 167, 69, 0.8);
  color: #ffffff;
  border: none;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  transition: background-color 0.3s, transform 0.3s;
}

.custom-arrow.left {
  left: 25px;
}

.custom-arrow.right {
  right: 25px;
}

.custom-arrow:hover {
  background-color: rgba(33, 136, 56, 0.8);
}

/* Ajuste da Paginação e Setas */
.carousel-container {
  position: relative;
  margin-bottom: 1rem;
}

.carousel-item-padding-40-px {
  padding: 0 15px;
}

/* Responsividade */
@media (max-width: 1200px) {
  .testimonials-section {
    padding: 2.5rem 1rem;
  }

  .testimonials-section-secondary {
    max-width: 1000px;
  }
}

@media (max-width: 1024px) {
  .testimonials-section h2 {
    font-size: 2.2rem;
  }

  .section-subtitle {
    font-size: 1rem;
  }

  .testimonial-avatar {
    width: 55px;
    height: 55px;
  }

  .testimonial-info h4 {
    font-size: 1.1rem;
  }

  .location {
    font-size: 0.85rem;
  }

  .rating .star {
    font-size: 0.95rem;
  }

  .testimonial-text {
    font-size: 0.95rem;
  }

  .custom-arrow {
    width: 40px;
    height: 40px;
  }

  .custom-arrow.left {
    left: 15px;
  }

  .custom-arrow.right {
    right: 15px;
  }
}

@media (max-width: 768px) {
  .testimonials-section {
    padding: 2rem 1rem;
    overflow: hidden;
  }

  .testimonials-section-secondary {
    max-width: 768px;
  }

  .testimonials-section h2 {
    font-size: 2rem;
  }

  .section-subtitle {
    font-size: 0.95rem;
  }

  .testimonial-card {
    padding: 1.5rem;
  }

  .testimonial-avatar {
    width: 50px;
    height: 50px;
  }

  .testimonial-info h4 {
    font-size: 1rem;
  }

  .location {
    font-size: 0.8rem;
  }

  .rating .star {
    font-size: 0.9rem;
  }

  .testimonial-text {
    font-size: 0.9rem;
  }

  .custom-arrow {
    width: 35px;
    height: 35px;
  }

  .custom-arrow.left {
    left: 10px;
  }

  .custom-arrow.right {
    right: 10px;
  }

  /* Ajusta o padding dos items do carrossel para evitar cortes */
  .carousel-item-padding-40-px {
    padding: 0 10px;
  }

  /* Evita que os cards sejam cortados no mobile */
  .carousel-container {
    padding: 0;
  }

  /* Ajuste do "Risco" do Título */
  .testimonials-section h2::after {
    width: 80%; /* Ajusta a largura do risco para 80% do título */
    bottom: -10px;
  }
}

@media (max-width: 480px) {
  .testimonials-section h2 {
    font-size: 1.8rem; /* Reduz o tamanho do título em celulares */
  }

  .testimonials-section-secondary {
    max-width: 480px;
  }

  .testimonials-section h2::after {
    width: 100%; /* O risco cobre todo o título */
    bottom: -10px;
  }

  .custom-arrow {
    width: 30px;
    height: 30px;
  }

  .custom-arrow.left {
    left: 5px;
  }

  .custom-arrow.right {
    right: 5px;
  }

  /* Ajuste adicional para garantir que o conteúdo não fique muito estreito */
  .testimonial-card {
    padding: 1rem;
  }

  .testimonial-text {
    font-size: 0.85rem;
  }
}

/* Animações */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideInDown {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes fadeInDown {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes fadeInUp {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}
