/* footer.css */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

:root {
  --footer-bg-color: #00c360;
  --footer-text-color: #fff;
  --footer-link-color: #fff;
  --footer-link-hover-color: #ddd;
  --footer-icon-size: 1.2rem;
  --footer-icon-hover-color: #bbb;
  --footer-font-family: 'Roboto', sans-serif;
  --footer-padding: 0.5rem 0.25rem;
  --footer-logo-font-size: 1.3rem;
  --footer-heading-font-size: 0.9rem;
  --footer-description-font-size: 0.8rem;
  --footer-bottom-font-size: 0.7rem;
  --footer-gap: 0.5rem;
  --anatel-logo-width: 150px;
  --anatel-logo-margin-top: 1rem;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.footer {
  background-color: var(--footer-bg-color);
  color: var(--footer-text-color);
  padding: var(--footer-padding);
  font-family: var(--footer-font-family);
}

.footer-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  gap: var(--footer-gap);
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.footer-section {
  padding: 0.2rem;
  color: var(--footer-text-color);
}

.footer-logo {
  font-size: var(--footer-logo-font-size);
  margin-bottom: 0.2rem;
  color: var(--footer-text-color);
  cursor: pointer;
}

.footer h3 {
  font-size: var(--footer-heading-font-size);
  margin-bottom: 0.2rem;
  position: relative;
  padding-bottom: 0.1rem;
  color: var(--footer-text-color);
}

.footer h3::after {
  content: '';
  width: 1.5rem;
  height: 0.08rem;
  background-color: var(--footer-text-color);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
}

.footer-description {
  font-size: var(--footer-description-font-size);
  line-height: 1.2;
  color: var(--footer-text-color);
  text-align: justify;
}

.footer-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-section ul li {
  margin-bottom: 0.2rem;
}

.footer-link-button,
.footer-external-link {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  color: var(--footer-link-color);
  text-decoration: none;
  font-size: 0.8rem;
  transition: color 0.3s ease, text-decoration 0.3s ease;
  outline: none;
}

.footer-link-button:hover,
.footer-link-button:focus,
.footer-external-link:hover,
.footer-external-link:focus {
  color: var(--footer-link-hover-color);
  text-decoration: underline;
}

.footer-section.contact p span {
  display: block;
  margin-bottom: 0.15rem;
  font-size: 0.75rem;
  color: var(--footer-text-color);
}

.footer-icons {
  display: flex;
  justify-content: center;
  gap: 0.4rem;
  margin-top: 0.2rem;
}

.footer-icons a {
  color: var(--footer-link-color);
  font-size: var(--footer-icon-size);
  transition: color 0.3s ease, transform 0.3s ease;
}

.footer-icons a:hover,
.footer-icons a:focus {
  color: var(--footer-icon-hover-color);
  transform: scale(1.1);
}

.footer-anatel-logo {
  margin-top: var(--anatel-logo-margin-top); 
  width: var(--anatel-logo-width); 
  max-width: 100%;
  height: auto;
}

.footer-bottom {
  margin-top: 0.5rem;
  text-align: center;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding-top: 0.3rem;
  color: var(--footer-text-color);
}

.footer-bottom p {
  font-size: var(--footer-bottom-font-size);
  margin: 0;
  color: var(--footer-text-color);
}

@media (max-width: 992px) {
  :root {
    --footer-padding: 0.5rem 0.25rem;
    --footer-gap: 0.5rem;
    --anatel-logo-width: 120px;
    --anatel-logo-margin-top: 0.8rem; 
  }

  .footer-content {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 0.5rem;
  }

  .footer-description {
    font-size: 0.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .footer-link-button,
  .footer-external-link {
    font-size: 0.75rem;
  }

  .footer-section.contact p span {
    font-size: 0.7rem;
  }

  .footer-icons a {
    font-size: 1.1rem;
  }

  .footer-anatel-logo {
    width: var(--anatel-logo-width);
    margin-top: var(--anatel-logo-margin-top); 
  }

  .footer-bottom p {
    font-size: 0.65rem;
  }
}

@media (max-width: 768px) {
  :root {
    --footer-padding: 0.4rem 0.2rem;
    --footer-gap: 0.4rem;
    --anatel-logo-width: 100px; 
    --anatel-logo-margin-top: 0.7rem; 
  }

  .footer-content {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .footer-description {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .footer-section {
    padding: 0.2rem 0;
  }

  .footer-icons {
    justify-content: center;
  }

  .footer-anatel-logo {
    width: var(--anatel-logo-width); 
    margin-top: var(--anatel-logo-margin-top); 
  }
}

@media (max-width: 480px) {
  :root {
    --footer-padding: 0.3rem 0.2rem;
    --footer-gap: 0.3rem;
    --footer-logo-font-size: 1.2rem;
    --footer-heading-font-size: 0.85rem;
    --footer-description-font-size: 0.75rem;
    --footer-bottom-font-size: 0.65rem;
    --anatel-logo-width: 80px; 
    --anatel-logo-margin-top: 0.6rem; 
  }

  .footer-logo {
    font-size: var(--footer-logo-font-size);
  }

  .footer h3 {
    font-size: var(--footer-heading-font-size);
  }

  .footer-description {
    font-size: var(--footer-description-font-size);
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .footer-link-button,
  .footer-external-link {
    font-size: 0.7rem;
  }

  .footer-icons a {
    font-size: 1rem;
  }

  .footer-anatel-logo {
    width: var(--anatel-logo-width);
    margin-top: var(--anatel-logo-margin-top);
  }

  .footer-bottom p {
    font-size: var(--footer-bottom-font-size);
  }
}
