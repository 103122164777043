/* src/styles/App.css */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
  }
  
  a {
    text-decoration: none;
  }
  
  h1, h2, h3 {
    color: #333;
  }
  
  p {
    color: #555;
  }
  