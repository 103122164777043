.levels-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #3ace84;
    border-radius: 16px;
    padding: 30px;
    max-width: 1200px;
    width: 90%;
    margin: 30px auto;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .levels-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .levels-text h3 {
    margin: 0;
    font-size: 1.8em;
    font-weight: bold;
    color: #333;
  }
  
  .levels-text p {
    margin: 5px 0 0;
    font-size: 1.1em;
    color: #666;
  }
  
  .levels-button {
    background-color: #3ace84;
    color: white;
    padding: 20px 40px;
    border: none;
    border-radius: 30px;
    font-size: 1.3em;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    transition: background-color 0.3s ease;
  }
  
  .levels-button:hover {
    background-color: #12c26a;
  }
  
  /* Responsividade para telas menores */
  @media (max-width: 768px) {
    .levels-card {
      flex-direction: column;
      padding: 20px;
      text-align: center;
    }
  
    .levels-text h3 {
      font-size: 1.5em;
    }
  
    .levels-text p {
      font-size: 1em;
    }
  
    .levels-button {
      padding: 15px 30px;
      font-size: 1.1em;
      margin-top: 15px; /* Adiciona espaçamento acima do botão */
    }
  }
  
  @media (max-width: 480px) {
    .levels-card {
      padding: 15px;
    }
  
    .levels-text h3 {
      font-size: 1.3em;
    }
  
    .levels-text p {
      font-size: 0.9em;
    }
  
    .levels-button {
      padding: 12px 24px;
      font-size: 1em;
    }
  }
  