.whatsapp-container {
     position: fixed;
     bottom: 20px;
     right: 20px;
     z-index: 1000;
   }
   
   .whatsapp-button {
     background-color: transparent;
     border: none;
     cursor: pointer;
     padding: 0;
     animation: moveUpDown 2s infinite ease-in-out;
   }
   
   .whatsapp-button img {
     width: 60px;
     height: 60px;
     transition: transform 0.3s;
   }
   
   .whatsapp-button:hover img {
     transform: scale(1.1);
   }
   
   @keyframes moveUpDown {
     0% {
       transform: translateY(0);
     }
     50% {
       transform: translateY(-10px);
     }
     100% {
       transform: translateY(0);
     }
   }
   
   .whatsapp-balloon {
     position: absolute;
     bottom: 70px;
     right: 0;
     background-color: #f5f5f5;
     padding: 20px; /* Padding padrão */
     border-radius: 10px;
     max-width: 250px;
     box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
     border: 3px solid #00c360;
     animation: fadeInSlideLeft 0.3s ease-in-out forwards;
     display: flex;
     flex-direction: column;
     align-items: flex-start; /* Alinha o conteúdo à esquerda */
   }
   
   .whatsapp-balloon p {
     margin: 0 0 10px 0;
     font-size: 16px;
     text-align: center;
     width: 100%; /* Garante que o texto ocupe toda a largura */
     padding-left: 10px; /* Ajusta o espaçamento do texto para a esquerda */
   }
   
   .send-message-button {
     background-color: #00c360;
     color: white;
     border: 2px solid #00c360;
     padding: 8px 12px;
     margin: 0px 50px 0px 50px;
     border-radius: 5px;
     cursor: pointer;
     font-weight: bold;
     transition: background-color 0.3s, color 0.3s;
     align-self: flex-start; /* Alinha o botão à esquerda dentro do balão */
   }
   
   .send-message-button:hover {
     background-color: white;
     color: #00c360;
   }
   
   .whatsapp-balloon::after {
     content: '';
     position: absolute;
     bottom: -12px;
     right: 30px;
     border-width: 10px 10px 0 10px;
     border-style: solid;
     border-color: transparent transparent transparent transparent;
     border-top-color: #00c360;
   }
   
   @keyframes fadeInSlideLeft {
     from {
       opacity: 0;
       transform: translateY(10px) translateX(0);
     }
     to {
       opacity: 1;
       transform: translateY(0) translateX(-30px);
     }
   }
   
   @media (max-width: 600px) {
     .whatsapp-balloon {
       max-width: 200px;
       padding: 15px;
       align-items: flex-start;
     }
   
     .whatsapp-balloon p {
       font-size: 16px;
       text-align: center;
     }
   
     .send-message-button {
       padding: 6px 10px;
       font-size: 14px;
       margin: 0px 30px 0px 30px;
     }
   
     .whatsapp-balloon::after {
       right: 25px;
     }
   }
   