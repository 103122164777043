/* HeroSection.css */

/* Importação da fonte Poppins do Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

.hero-section {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: url('../../assets/Fundo6.png') no-repeat center center/cover;
  background-color: #f5f5f5;
  padding: 0rem 2rem;
  padding-top: 6rem;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
  font-family: 'Poppins', sans-serif;
}

.hero-text {
  flex: 1;
  max-width: 500px;
  text-align: left;
  animation: fadeInLeft 1s ease-out;
  position: relative; /* Para controlar o z-index */
  z-index: 1; /* Garante que o texto fique acima da imagem */
}

.service-badge {
  display: inline-block;
  padding: 0.1rem 0.8rem;
  background-color: #e0e0e0;
  border-radius: 12px;
  font-size: 1rem;
  color: #666;
  margin-bottom: 0.5rem;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.service-badge:hover {
  background-color: #00c360;
  color: white;
}

.hero-text h1 {
  font-size: 2.8rem;
  color: #333;
  font-weight: 600;
  line-height: 1.2;
  margin: 0;
  animation: fadeInDown 1.2s ease-out;
  letter-spacing: 0.05rem;
}

.hero-text p {
  font-size: 1.2rem;
  text-align: left;
  color: #555;
  margin: 1rem 0;
  line-height: 1.6;
  animation: fadeInUp 1.3s ease-out;
}

.hero-buttons {
  margin-top: 1.8rem;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: flex-start;
  animation: fadeInUp 1.4s ease-out;
}

.hero-buttons .btn-primary,
.hero-buttons .btn-secondary {
  display: inline-flex;
  align-items: center;
  padding: 0.8rem 1.5rem;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  font-weight: 500;
  color: #fff; /* Cor padrão para o texto e a seta */
  white-space: nowrap; /* Evita quebra de linha */
}

.hero-buttons .btn-primary {
  background-color: #00c360;
  color: #f8f9fa;
  border: 2px solid #00c360;
}

.hero-buttons .btn-primary::after {
  content: '➔';
  font-size: 1rem;
  margin-left: 0.5rem;
  color: #f8f9fa;
  opacity: 1;
  transition: color 0.3s ease;
}

.hero-buttons .btn-primary:hover {
  background-color: #f8f9fa;
  color: #00c360;
  transform: translateY(-2px);
}

.hero-buttons .btn-primary:hover::after {
  color: #00c360;
}

.hero-buttons .btn-secondary {
  background-color: #f8f9fa;
  color: #555;
  border: 2px solid #555;
}

.hero-buttons .btn-secondary::after {
  content: '➔';
  font-size: 1rem;
  margin-left: 0.5rem;
  color: #555;
  opacity: 1;
  transition: color 0.3s ease;
}

.hero-buttons .btn-secondary:hover {
  background-color: #555;
  color: #f8f9fa;
  transform: translateY(-2px);
}

.hero-buttons .btn-secondary:hover::after {
  color: #f8f9fa;
}

.hero-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  position: relative;
  margin-top: 7rem;
  animation: float 4s ease-in-out infinite;
  z-index: 0; /* Garante que a imagem fique abaixo do texto */
}

.hero-image img {
  width: 100%;
  max-width: 600px;
  animation: fadeInRight 1.5s ease-out;
  border-radius: 8px;
  margin-top: -50px;
}

.hero-image::before {
  content: '';
  position: absolute;
  width: 800px;
  height: 800px;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(0, 123, 255, 0.15), rgba(0, 123, 255, 0) 40%);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

/* Animações */
@keyframes fadeInLeft {
  from { opacity: 0; transform: translateX(-20px); }
  to { opacity: 1; transform: translateX(0); }
}

@keyframes fadeInDown {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes fadeInUp {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes fadeInRight {
  from { opacity: 0; transform: translateX(20px); }
  to { opacity: 1; transform: translateX(0); }
}

@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

/* Responsividade */
@media (max-width: 1024px) {
  .hero-text h1 { font-size: 2.4rem; }
  .hero-text p { font-size: 1rem; }
  .hero-image { max-width: 500px; }
}

@media (max-width: 768px) {
  .hero-section {
    flex-direction: column;
    text-align: center;
    background: url('../../assets/Fundo6_mobile.png') no-repeat center center/cover;
    padding-top: 8rem; /* Aumenta o padding-top para evitar sobreposição */
  }

  .hero-text {
    padding-right: 0;
    text-align: center;
    margin-top: 8rem;
  }

  .hero-buttons {
    justify-content: center;
  }

  .hero-image {
    max-width: 400px;
    margin-top: 3rem;
  }

  .hero-text h1 { font-size: 2rem; }
}

@media (max-width: 480px) {
  .hero-section {
    flex-direction: column;
    align-items: center;
    background: url('../../assets/Fundo6_mobile.png') no-repeat center center/cover;
    padding: 2rem 1rem; /* Ajusta o padding para melhor espaçamento */
    padding-top: 8rem; /* Aumenta o padding-top para evitar sobreposição */
  }

  .hero-text {
    max-width: 100%;
    text-align: center;
    margin-top: 8rem;
  }

  .hero-text p {
    font-size: 0.9rem;
    text-align: center; /* Centraliza o texto */
    line-height: 1.4;
  }

  .hero-buttons {
    flex-direction: row; /* Mantém os botões na mesma linha */
    gap: 0.5rem; /* Ajusta o gap */
    margin-top: 1.5rem;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap; /* Evita que quebre linha */
  }

  .hero-buttons .btn-primary{
    font-size: 0.9rem;
    padding: 0.6rem 1.2rem;
    width: 41%; /* Permite que dois botões caibam lado a lado */
    max-width: none; /* Remove o max-width */
    white-space: nowrap; /* Evita que o texto quebre linha */
    overflow: hidden; /* Evita que o texto transborde */
    text-overflow: ellipsis; /* Adiciona reticências se o texto for muito longo */
  }

  .hero-buttons .btn-secondary {
    font-size: 0.9rem;
    padding: 0.6rem 1.2rem;
    width: 55%; /* Permite que dois botões caibam lado a lado */
    max-width: none; /* Remove o max-width */
    white-space: nowrap; /* Evita que o texto quebre linha */
    overflow: hidden; /* Evita que o texto transborde */
    text-overflow: ellipsis; /* Adiciona reticências se o texto for muito longo */
  }

  /* Ajuste específico para aumentar a largura dos botões no mobile */
  .hero-buttons .btn-secondary {
    padding-right: 1.5rem; /* Adiciona mais espaço à direita para a seta */
  }

  .hero-image {
    max-width: 300px;
    margin-top: 2rem;
  }

  .hero-text h1 {
    font-size: 1.8rem;
    line-height: 1.2;
  }

  /* Remover margem negativa no mobile */
  .hero-image img {
    margin-top: 0;
  }
}
