/* Estilo Geral do Header */
.header {
  position: fixed;
  top: 0;
  width: 100%;
  transition: background-color 0.3s ease-in-out;
  z-index: 1000;
  background-color: transparent;
}

.header.scrolled {
  background-color: white;
}

/* Barra Superior */
.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #00c360;
  padding: 5px 20px;
  color: white;
}

.left-icons, .right-icons {
  display: flex;
  align-items: center;
  font-size: 12px;
  min-width: 150px; /* Largura mínima para consistência */
}

.left-icons span, .right-icons span {
  margin-left: 5px;
}

.right-icons .instagram-icon {
  margin-left: 15px;
}

.header-message {
  display: flex;
  align-items: center;
  font-size: 16px;
  justify-content: center;
  flex-grow: 1;
}

.rocket-icon {
  margin-right: 5px;
}

/* Barra Inferior (Header Principal) */
.header-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
}

.logo img {
  height: 40px;
}

/* Estilo e Animação do Menu */
.nav-menu {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  padding: 10px 20px;
  border-radius: 12px;
  transition: all 0.3s ease;
}

.nav-menu a,
.nav-menu .mobile-contact-link,
.nav-menu .mobile-login-link {
  position: relative;
  margin: 0 15px;
  text-decoration: none;
  font-size: 14px;
  color: #333;
  transition: color 0.3s, transform 0.3s;
  cursor: pointer; /* Adicionado */
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  display: flex;
  align-items: center;
}

.nav-menu a:hover,
.nav-menu .mobile-contact-link:hover,
.nav-menu .mobile-login-link:hover {
  transform: translateY(-3px);
}

/* Estilo para o Link Ativo */
.nav-menu a.active {
  color: #00c360;
  font-weight: bold;
}

/* Animação de sublinhado (presente apenas quando há uma página ativa) */
.nav-menu a:after,
.nav-menu .mobile-contact-link:after,
.nav-menu .mobile-login-link:after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 0;
  height: 2px;
  background: #00c360;
  transition: width 0.3s ease;
}

.nav-menu a:hover:after,
.nav-menu .mobile-contact-link:hover:after,
.nav-menu .mobile-login-link:hover:after,
.nav-menu a.active:after {
  width: 100%;
}

/* Botões de Contato e Login no Header Principal */
.header-buttons {
  display: flex;
  align-items: center;
  gap: 10px; /* Espaçamento entre os botões */
}

.contact-button,
.login-button {
  padding: 8px 16px;
  border: 1px solid #00c360;
  border-radius: 4px;
  background-color: white;
  color: #666;
  cursor: pointer; /* Adicionado */
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
  font-size: 14px;
}

.contact-button:hover,
.login-button:hover {
  background-color: #00c360;
  border-color: #00c360;
  color: white;
}

/* Ícone do Menu Hambúrguer */
.menu-icon {
  display: none;
  cursor: pointer; /* Adicionado */
}

/* Estilo dos Contatos e Login no Menu Hamburguer */
.mobile-contacts {
  display: none; /* Inicialmente escondido */
  flex-direction: column;
}

.mobile-contacts .contact-link,
.mobile-contacts .login-link {
  display: flex;
  align-items: center;
  margin: 10px 0;
  text-decoration: none;
  color: #333;
  font-size: 14px;
  cursor: pointer; /* Adicionado */
  transition: color 0.3s, background-color 0.3s, transform 0.3s;
  padding: 10px;
  border-radius: 4px;
}

.mobile-contacts .contact-link:hover,
.mobile-contacts .login-link:hover {
  color: #fff;
  background-color: #00c360;
  transform: scale(1.05);
}

.mobile-contacts .contact-link svg,
.mobile-contacts .login-link svg {
  margin-right: 8px;
  transition: transform 0.3s;
}

.mobile-contacts .contact-link:hover svg,
.mobile-contacts .login-link:hover svg {
  transform: rotate(360deg);
}

/* Responsividade */
@media (max-width: 768px) {
  .left-icons, .right-icons {
    display: none;
  }

  .header-message {
    font-size: 16px;
    justify-content: center;
  }

  .header-main {
    padding: 5px 10px;
  }

  .logo img {
    height: 30px;
  }

  .menu-icon {
    display: block;
    margin-left: 20px;
  }

  .nav-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    flex-direction: column;
    background-color: white;
    padding: 20px;
    display: none;
    transition: all 0.3s ease;
  }

  .nav-menu.open {
    display: flex;
  }

  .nav-menu a,
  .nav-menu .mobile-contact-link,
  .nav-menu .mobile-login-link {
    margin: 10px 0;
  }

  /* Efeito de sublinhado no menu responsivo */
  .nav-menu a:after,
  .nav-menu .mobile-contact-link:after,
  .nav-menu .mobile-login-link:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background: #00c360;
    transition: width 0.3s ease;
  }

  .nav-menu a:hover:after,
  .nav-menu .mobile-contact-link:hover:after,
  .nav-menu .mobile-login-link:hover:after,
  .nav-menu a.active:after {
    width: 100%;
  }

  .header-buttons {
    display: none; /* Esconde os botões no header principal na visualização mobile */
  }

  /* Exibir contatos e login no menu hamburguer */
  .mobile-contacts {
    display: flex;
    flex-direction: column;
  }

  .mobile-contact-link,
  .mobile-login-link {
    display: block; /* Mostra os botões no menu hambúrguer */
    background-color: #00c360;
    color: white;
    padding: 12px 0; /* Padding consistente */
    border-radius: 4px;
    text-align: center;
    justify-content: center;
    /* Remove margin-top individual */
    transition: background-color 0.3s ease, transform 0.3s ease;
    font-size: 16px; /* Aumenta o tamanho da fonte para melhor legibilidade */
    width: 100%; /* Faz com que os botões ocupem toda a largura do contêiner */
    box-sizing: border-box; /* Garante que o padding não afete a largura total */
  }

  .mobile-contact-link:hover,
  .mobile-login-link:hover {
    transform: scale(1.05);
  }

  /* Ajustes adicionais para melhorar a aparência */
  .nav-menu.open {
    padding: 20px; /* Espaçamento interno consistente */
  }
}
