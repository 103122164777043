/* FeaturesSection.css */

/* Importação da fonte Poppins do Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

.features-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url('../../assets/Fundo7.png') no-repeat center center/cover;
  background-color: #f5f5f5;
  padding: 2rem 2rem;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  position: relative;
  overflow: hidden;
}

.features-section h2 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 1.5rem;
  position: relative;
  padding-bottom: 0rem;
  animation: fadeIn 1s ease-out;
  border-bottom: 3px solid #00c360;
  
  /* Remover white-space: nowrap em telas menores */
  white-space: normal;
  overflow: visible;
  text-overflow: unset;
  
  /* Transição para mudanças suaves */
  transition: font-size 0.3s ease;
}

.features-intro {
  font-size: 1.2rem;
  color: #555;
  max-width: 700px;
  margin-bottom: 2.5rem;
  animation: fadeInUp 1s ease-out;
}

.features-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
  width: 100%;
  max-width: 1200px;
}

.feature-item {
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  animation: fadeInUp 1.2s ease-out;
  
  /* Balancear altura e alinhamento dos elementos */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.feature-item:hover {
  transform: translateY(-8px) scale(1.02); /* Adiciona leve escala no hover */
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  background-color: #f0fff4; /* Mudança sutil de fundo ao hover */
}

.feature-item h3 {
  font-size: 1.3rem; /* Tamanho reduzido em telas maiores */
  color: #333;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  
  /* Transição suave para mudança de cor */
  transition: color 0.3s ease;
}

.feature-item:hover h3 {
  color: #00c360; /* Cor de destaque ao hover */
}

.feature-item p {
  font-size: 0.9rem; /* Tamanho reduzido em telas maiores */
  color: #666;
  line-height: 1.6;
  
  /* Mantém a flexibilidade sem alterar tamanho do texto */
  flex-grow: 1;
}

.feature-icon {
  width: 80px;
  height: 80px;
  margin: 0 auto 1rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e0f3e7;
  font-size: 2rem;
  color: #00c360;
  transition: background-color 0.3s ease, transform 0.3s ease, color 0.3s ease;
}

.feature-item:hover .feature-icon {
  background-color: #00c360;
  color: #ffffff;
  transform: scale(1.1); /* Aumento do ícone no hover */
}

/* Ícones personalizados para cada item */
.reliability-icon::before {
  content: '⏰'; /* Relógio para Confiabilidade */
}

.speed-icon::before {
  content: '⚡'; /* Ícone representativo de velocidade */
}

.security-icon::before {
  content: '🔒';
}

.support-icon::before {
  content: '📞';
}

/* Animações */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes fadeInUp {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Responsividade */
@media (max-width: 1024px) {
  .features-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .features-section h2 {
    font-size: 2.2rem;
  }

  .feature-item h3 {
    font-size: 1.4rem; /* Mantém o tamanho para evitar estiramento */
  }

  .feature-item p {
    font-size: 1rem; /* Mantém o tamanho para evitar estiramento */
  }
}

@media (max-width: 768px) {
  .features-container {
    grid-template-columns: 1fr;
  }

  .features-section {
    background: url('../../assets/Fundo7_mobile.png') no-repeat center center/cover;
  }

  .features-section h2 {
    font-size: 2rem; /* Ajuste para telas médias */
    white-space: normal; /* Permite quebra de linha */
    overflow: visible; /* Permite que o texto seja visível */
    text-overflow: unset; /* Remove a reticência */
    
    /* Remover border-bottom e usar uma linha centralizada */
    border-bottom: none;
    position: relative;
  }

  .features-section h2::after {
    content: '';
    width: 50px; /* Largura menor para telas menores */
    height: 3px;
    background-color: #00c360;
    position: absolute;
    left: 50%;
    bottom: -10px;
    transform: translateX(-50%);
    border-radius: 2px;
  }

  .feature-item {
    max-width: 90%;
    margin: 0 auto 1.5rem;
    padding: 1.5rem; /* Reduz o padding para melhor encaixe */
  }

  .feature-icon {
    width: 60px;
    height: 60px;
    font-size: 1.5rem; /* Reduz o tamanho do ícone */
  }
}

@media (max-width: 480px) {
  .features-section {
    background: url('../../assets/Fundo7_mobile.png') no-repeat center center/cover;
  }

  .features-section h2 {
    font-size: 1.8rem; /* Reduzido ainda mais para evitar quebra de linha */
    white-space: normal; /* Permite quebra de linha */
    overflow: visible; /* Permite que o texto seja visível */
    text-overflow: unset; /* Remove a reticência */
    
    /* Adiciona padding lateral para garantir espaço */
    padding: 0 1rem;
    
    /* Remover a linha do título ou ajustar conforme necessário */
    border-bottom: none;
  }

  .features-section h2::after {
    width: 50px; /* Largura menor para telas menores */
    bottom: -10px;
  }

  .features-intro {
    font-size: 1rem;
    max-width: 100%;
    padding: 0 1rem;
  }

  .feature-item h3 {
    font-size: 1.3rem;
    
    /* Prevenção de quebra de linha */
    white-space: normal;
    overflow: visible;
    text-overflow: unset;
  }

  .feature-item p {
    font-size: 0.9rem;
  }

  .feature-item {
    padding: 1.8rem;
  }

  .feature-icon {
    width: 50px;
    height: 50px;
    font-size: 1.2rem; /* Reduz ainda mais o tamanho do ícone */
  }

  /* Ajustar o grid-gap para telas menores */
  .features-container {
    gap: 1rem;
  }
}
