.contact-section {
  display: flex;
  background: url('../../assets/Fundo6.png') no-repeat center center/cover;
  flex-direction: column;
  align-items: center;
  padding: 4rem 2rem;
}

.contact-header h2 {
  display: flex;
  align-items: center;
  font-size: 2rem;
  margin-bottom: 20px;
  padding-top: 25px;
}

.contact-icon {
  margin-right: 10px;
  max-width: 20%;
}

.contact-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  flex-direction: row;
}

.contact-image {
  flex: 1;
  max-width: 50%;
  padding: 25px 50px;
}

.contact-image img {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
}

.contact-form-container {
  flex: 1;
  max-width: 50%;
  padding: 25px 40px;
}

.contact-form-container p {
  font-size: 1rem;
  margin-bottom: 20px;
  text-align: center;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
  gap: 10px;
}

.form-group > div {
  flex: 1;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #2E7D32;
  border-radius: 5px;
  box-sizing: border-box;
  font-family: Arial, sans-serif; 
  font-size: 13px; 
}

textarea {
  resize: none;
  height: 100px;
}

.submit-button {
  padding: 10px 20px;
  margin: 0px 120px;
  background-color: #00c360;
  color: #f8f9fa;
  border: 2px solid #00c360;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
}

.submit-button:hover {
  background-color: #f8f9fa;
  color: #00c360;
  border: 2px solid #00c360;
}

.form-terms {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.form-terms input {
  margin-right: 10px;
}

label {
  font-family: Arial, sans-serif; /* Substitua pela fonte desejada */
  font-size: 13px; /* Ajuste o tamanho da fonte */
  padding: 8px 2px;
}

/* Responsividade */
@media (max-width: 768px) {
  .contact-section {
    background: url('../../assets/Fundo6_mobile.png') no-repeat center center/cover;
  }

  .contact-content {
    flex-direction: column;
    align-items: center;
  }

  .contact-image,
  .contact-form-container {
    max-width: 100%;
    padding: 20px;
  }

  .submit-button {
    margin: 0 auto;
    width: 100%;
  }
}
