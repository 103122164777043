.error-container {
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     height: 100vh;
     background-color: #f4f4f4;
     padding: 20px;
   }
   
   .error-code {
     margin: 0 0 20px 0;
     font-size: 50px;
     color: #00c360;
     background-color: #e8efe7;
     width: 200px;
     height: 200px;
     border-radius: 50%;
     display: flex;
     justify-content: center;
     align-items: center;
   }
   
   .vertical-line {
     width: 1px;
     height: 150px;
     background-color: lightgray;
     margin: 20px 0;
   }
   
   /* Tornar a linha horizontal em dispositivos móveis */
   @media (max-width: 767px) {
     .vertical-line {
       width: 250px; /* Largura da linha horizontal */
       height: 1px; /* Altura da linha horizontal */
       margin: 20px 0;
     }
   }
   
   .error-message {
     text-align: center;
     max-width: 90%;
     font-family: Arial, sans-serif;
   }
   
   .error-code h1 {
     color: #00c360;
   }
   
   .error-message h2 {
     font-size: 20px;
     margin-bottom: 10px;
   }
   
   .error-message p {
     font-size: 14px;
     color: #666;
   }
   
   .back-home-button {
     display: inline-block;
     padding: 10px 20px;
     background-color: #00c360;
     color: white;
     border-radius: 20px;
     font-weight: 550;
     text-decoration: none;
     font-size: 16px;
     margin-top: 20px;
   }
   
   .back-home-button:hover {
     background-color: #028644;
   }
   
   @media (min-width: 768px) {
     .error-container {
       flex-direction: row;
     }
     
     .error-code {
       margin-right: 50px;
       width: 250px;
       height: 250px;
     }
     
     .vertical-line {
       height: 200px;
     }
     
     .error-message {
       text-align: left;
       margin-left: 50px;
       max-width: 500px;
     }
     
     .error-message h2 {
       font-size: 24px;
     }
     
     .error-message p {
       font-size: 16px;
     }
   }
   