@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

:root {
    --primary-color: #00c360; 
    --secondary-color: #028644;
    --background-color: #f1f8e9;
    --white: #ffffff;
    --text-color: #333333;
    --border-color: #b2dfdb;
    --hover-color: #028644;
}

body {
    margin: 0;
    font-family: 'Poppins', sans-serif; 
    background-color: var(--background-color);
}

.hero-section {
    display: flex;
    justify-content: center;
    align-items: center; 
    background: url('../assets/Fundo6.png') no-repeat center center/cover; 
    background-color: #f5f5f5;
    padding: 0 2rem;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
    font-family: 'Poppins', sans-serif;
}

.city-selector-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
}

.city-selector-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.95);
    padding: 50px;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.city-selector-content:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
}

.city-selector-logo {
    width: 250px; 
    height: auto;
    margin-right: 50px;
}

.city-selector-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.city-selector-form h1 {
    margin-bottom: 25px;
    color: var(--text-color);
    font-size: 2em;
    text-align: left;
}

.dropdown-wrapper {
    position: relative;
    width: 100%;
    margin-bottom: 25px;
}

.city-selector-dropdown {
    width: 100%;
    padding: 12px 15px;
    padding-right: 40px; 
    border: 1.5px solid var(--border-color);
    border-radius: 8px;
    font-size: 16px;
    background-color: var(--white);
    color: var(--text-color);
    appearance: none;
    cursor: pointer;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.city-selector-dropdown:focus {
    border-color: var(--primary-color);
    box-shadow: 0 0 5px rgba(0, 105, 92, 0.5);
    outline: none;
}

.dropdown-arrow {
    position: absolute;
    top: 50%;
    right: 15px;
    width: 0; 
    height: 0; 
    pointer-events: none;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid var(--text-color);
    transform: translateY(-50%);
}

.btn-primary {
    padding: 12px 25px;
    background-color: var(--primary-color);
    color: var(--white);
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease, transform 0.2s ease;
    align-self: stretch;
    text-align: center;
}

.btn-primary:hover {
    background-color: var(--hover-color);
}

.btn-primary:active {
    transform: scale(0.98);
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.dropdown-wrapper .dropdown-arrow {
    border-top-color: var(--text-color);
}

@media (max-width: 1024px) {
    .city-selector-content {
        padding: 40px;
    }

    .city-selector-logo {
        width: 200px;
        margin-right: 30px;
    }

    .city-selector-form h1 {
        font-size: 1.75em;
        text-align: center;
    }

    .dropdown-wrapper {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .hero-section {
        background: url('../assets/Fundo6_mobile.png') no-repeat center center/cover; 
    }

    .city-selector-content {
        flex-direction: column;
        text-align: center;
    }

    .city-selector-logo {
        margin-right: 0;
        margin-bottom: 30px;
        width: 180px;
    }

    .city-selector-form {
        align-items: center;
        width: 100%;
    }

    .city-selector-dropdown {
        width: 100%;
    }

    .btn-primary {
        width: 100%;
    }

    .dropdown-wrapper .dropdown-arrow {
        right: 20px;
    }
}

@media (max-width: 480px) {
    .hero-section {
        background: url('../assets/Fundo6_mobile.png') no-repeat center center/cover; 
    }
    
    .city-selector-content {
        padding: 30px 20px;
    }

    .city-selector-logo {
        width: 150px;
        margin-bottom: 20px;
    }

    .city-selector-form h1 {
        font-size: 1.5em;
    }

    .dropdown-wrapper .dropdown-arrow {
        right: 15px;
    }
}
