/* Estilização da seção de preços */
.pricing-section {
  text-align: center;
  background-color: #f5f5f5;
  padding: 3rem 1rem;
  padding-top: 5rem;
  overflow-x: hidden; /* Remove rolagem horizontal */
}

.section-header {
  margin-bottom: 3rem;
}

.section-header h2 {
  font-size: 2.2rem;
  font-weight: bold;
  color: #000;
  margin-bottom: 0.5rem;
}

.section-header p {
  font-size: 1.1rem;
  color: #666;
  max-width: 700px;
  margin: 0 auto;
}

/* Container dos cards */
.pricing-cards {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  gap: 1.3rem; /* Pequena redução para manter um bom espaçamento */
  margin: 0 auto;
  max-width: 1300px; /* Reduzido para evitar rolagem horizontal */
}

/* Cada card teve sua largura reduzida em 5% */
.pricing-card {
  flex: none;
  width: 305px; /* Reduzido de 320px para 305px */
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  padding: 1rem 1.2rem;
  color: #333;
  text-align: center;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  margin: 0.5rem 0;
}

.pricing-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
}

/* Títulos e textos dentro do card */
.pricing-card h3 {
  text-align: left;
  color: #3ace84;
  font-size: 1.1rem;
}

.card-type {
  margin-top: -10px;
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: -15px;
}

.card-internet {
  margin-top: -90px;
  font-size: 28px;
  margin-bottom: 90px;
  color: #3ace84;
}

.card-mbps {
  margin-top: -90px;
  font-size: 16px;
  margin-bottom: 70px;
  color: #3ace84;
}

.card-price {
  font-size: 1.3em;
  margin-bottom: 0.8rem;
  color: #3ace84;
}

/* Lista de features */
.card-features {
  list-style: none;
  padding: 0;
  color: #3ace84;
  margin: 1rem 0;
}

.card-features li {
  display: flex;
  align-items: center;
  font-size: 0.85em;
  margin-bottom: 0.4rem;
  color: #3ace84;
}

/* Ícones de checagem */
.check-circle {
  margin-right: 6px;
  width: 20px;
  height: 20px;
  background-color: #3ace84;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.check-mark {
  width: 8px;
  height: 4px;
  border-left: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff;
  transform: rotate(-45deg);
  margin-bottom: 2px;
}

/* Botão de escolher plano */
.card-button {
  background-color: #3ace84;
  border: none;
  color: white;
  padding: 8px 16px;
  font-size: 0.95em;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 0.8rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.card-button:hover {
  background-color: #028a4d;
  transform: scale(1.05);
}

/* Linha horizontal com texto no meio */
.horizontal-line-with-text {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
  position: relative;
}

.horizontal-line-with-text span {
  margin: 0 1rem;
  font-size: 0.9rem;
  font-weight: bold;
  text-transform: uppercase;
}

/* Para o plano principal */
.principal-plan {
  background-color: #3ace84;
  color: white;
  border: none;
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.principal-plan:hover {
  transform: scale(1.07);
}

.principal-plan .card-price,
.principal-plan .card-internet,
.principal-plan .card-mbps,
.principal-plan .card-type,
.principal-plan .card-plan {
  color: #ffffff;
}

.principal-plan .card-button {
  background-color: #ffffff;
  color: #3ace84;
  border: 1px solid #3ace84;
}

.principal-plan .card-button:hover {
  background-color: #f0f0f0;
}

.principal-plan .check-circle {
  background-color: #ffffff;
}

.principal-plan .check-mark {
  border-color: #03ACF2;
}

.principal-plan .card-features li {
  color: #ffffff;
}

/* Botões de streaming */
.streaming-button {
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  margin: 0 5px;
  transition: transform 0.3s ease;
}

.streaming-button:hover {
  transform: scale(1.1);
}

.streaming-logo {
  width: 50px;
  height: auto;
  display: block;
}

/* Gráficos de velocidade */
.speed-chart {
  margin: 1rem 0;
}

/* Ajustes para telas menores */
@media (max-width: 1200px) {
  .pricing-cards {
    max-width: 1200px; /* Ajusta melhor em telas menores */
    gap: 1.2rem;
  }
}

@media (max-width: 1024px) {
  .pricing-cards {
    flex-wrap: wrap; /* Permite quebrar em 2 linhas se necessário */
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .pricing-cards {
    flex-wrap: wrap;
    gap: 1rem;
  }

  .pricing-card {
    width: 100%;
    max-width: 280px;
  }
}

@media (max-width: 480px) {
  .pricing-cards {
    flex-direction: column;
    gap: 0.8rem;
  }

  .pricing-card {
    max-width: 100%;
  }
}
