/* Geral */
.about-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 70px 20px; /* Aumenta o padding-top para evitar corte do título */
  background-color: #3ace8424;
  scroll-margin-top: 80px; /* Ajusta a margem para navegação com cabeçalho fixo */
}

.about-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  gap: 50px;
  position: relative;
}

/* Responsividade para telas menores */
@media (max-width: 1200px) {
  .about-content {
    max-width: 1000px;
    gap: 40px;
  }
}

@media (max-width: 1024px) {
  .about-content {
    gap: 30px;
  }
}

@media (max-width: 768px) {
  .about-content {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .about-text {
    text-align: center;
  }
}

.about-image-container {
  position: relative;
}

.about-image {
  width: 100%;
  max-width: 400px;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
}

/* Texto */
.about-text {
  flex: 1;
  max-width: 800px;
}

.about-title {
  font-size: 35px;
  font-weight: bold;
  color: #2c3e50;
  text-align: center;
  margin-bottom: 10px; /* Aumenta a margem inferior para espaçamento */
  animation: fadeIn 1s ease-in-out;
}

.subtitle {
  font-size: 22px;
  color: #777;
  margin-bottom: 15px; /* Aumenta a margem inferior para espaçamento */
  text-align: center;
  animation: fadeIn 1s ease-in-out;
}

/* Estatísticas */
.about-stats {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin: 20px 0;
  flex-wrap: wrap; /* Permite que os cards quebrem linha se necessário */
}

.stat-card {
  border: 2px solid #00c360;
  border-radius: 15px;
  padding: 20px;
  flex: 1 1 150px; /* Flex-grow: 1, Flex-shrink: 1, Flex-basis: 150px */
  text-align: center;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  min-width: 150px;
  max-width: 200px; /* Limita a largura máxima para evitar estiramento excessivo */
}

.stat-card h3 {
  font-size: 32px;
  font-weight: bold;
  color: #00c360;
  margin-top: 0px;
  margin-bottom: 5px;
}

.stat-card span {
  font-size: 16px;
  color: #2c3e50;
  font-weight: bold;
}

.stat-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
}

/* Descrição */
.about-description {
  font-size: 18px;
  color: #555;
  line-height: 1.8;
  margin-top: 20px;
  text-align: justify;
  position: relative;
  padding-left: 30px; /* Espaço para o risco */
}

.about-description p {
  text-align: justify;
  position: relative;
}

.about-description::before {
  content: "";
  position: absolute;
  left: 0; /* Posicionando o risco à esquerda */
  top: 0;
  width: 4px;
  height: 100%;
  background-color: #00c360;
}

/* Animações */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsividade extra */
@media (max-width: 1024px) {
  .about-title {
    font-size: 30px;
  }

  .subtitle {
    font-size: 20px;
    margin-bottom: 12px;
  }

  .about-description {
    font-size: 17px;
    padding-left: 25px;
  }
}

@media (max-width: 768px) {
  .about-title {
    font-size: 28px;
  }

  .subtitle {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .about-description {
    font-size: 16px;
    padding-left: 20px;
  }
}

@media (max-width: 600px) {
  .about-section {
    padding: 60px 15px; /* Ajusta o padding para telas muito pequenas */
  }

  .about-title {
    font-size: 26px;
  }

  .subtitle {
    font-size: 16px;
    margin-bottom: 8px;
  }

  .about-description {
    font-size: 15px;
    padding-left: 15px;
  }
}
