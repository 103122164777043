/* Geral */
.faq-section {
  padding: 80px 1.5rem 50px 1.5rem; /* Aumentei o padding-top para 80px */
  background-color: #f5f5f5;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  scroll-margin-top: 100px; /* Ajuste conforme a altura do seu cabeçalho fixo */
}

.faq-section h2 {
  font-size: 2rem; /* Menor tamanho do título */
  color: #1d1d1f;
  margin-bottom: 0.5rem;
  display: inline-block;
  border-bottom: 3px solid #00c360;
  animation: fadeInDown 0.8s ease-out;
}

.section-subtitle {
  font-size: 1rem; /* Menor tamanho do subtítulo */
  color: #6c757d;
  margin-bottom: 2rem;
  animation: fadeInUp 0.8s ease-out;
}

.faq {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem; /* Menor espaço entre os cards */
  max-width: 1000px; /* Menor largura máxima */
  margin: 0 auto;
}

.faq-item {
  background-color: #ffffff;
  padding: 1rem; /* Menor padding interno dos cards */
  border-radius: 8px; /* Bordas menores */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
  position: relative;
  overflow: hidden;
}

.faq-item:hover {
  transform: translateY(-3px);
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
}

.faq-question {
  font-size: 1rem; /* Menor tamanho do texto da pergunta */
  color: #333;
  font-weight: 600;
  padding-left: 2rem;
  text-align: left;
  position: relative;
  display: flex;
  align-items: center;
}

.faq-question::before {
  content: ">";
  position: absolute;
  left: 0rem;
  font-size: 1.7rem; /* Diminui o tamanho da seta */
  font-weight: bold;
  color: #00c360;
  letter-spacing: 0.2em; /* Alonga a seta para o lado */
  transition: transform 0.3s ease;
}

.faq-item.active .faq-question::before {
  transform: rotate(90deg);
}

.faq-answer {
  font-size: 0.9rem; /* Menor tamanho do texto da resposta */
  color: #555;
  margin-top: 0.5rem;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease;
}

.faq-item.active .faq-answer {
  max-height: 150px; /* Limita a altura da resposta expandida */
}

/* Responsividade */
@media (max-width: 1024px) {
  .faq {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .faq {
    grid-template-columns: 1fr;
  }

  .faq-section {
    padding: 60px 1rem 40px 1rem; /* Ajuste de padding para telas menores */
  }

  .faq-question {
    font-size: 0.95rem;
  }

  .faq-answer {
    font-size: 0.85rem;
  }
}

@media (max-width: 600px) {
  .faq-section {
    padding: 60px 1rem 40px 1rem; /* Ajuste de padding para telas muito pequenas */
  }

  .faq-question {
    font-size: 0.9rem;
  }

  .faq-answer {
    font-size: 0.8rem;
  }
}
